import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-button-link',
  imports: [NgClass, RouterLink],
  templateUrl: './button-link.component.html',
  styleUrl: './button-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLinkComponent {
  disabled = input<boolean>(false);
  withIcon = input<boolean>(false);
  variety = input<'primary' | 'secondary' | 'grey'>('primary');
  link = input.required<string>();
  width = input<'max-content' | 'stretch'>('max-content');
  narrowPadding = input<boolean>(false);
}

import { Component, forwardRef, input } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-input-phone',
  imports: [FormsModule, NgxMaskDirective],
  templateUrl: './input-phone.component.html',
  styleUrl: './input-phone.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputPhoneComponent,
      multi: true,
    },
    provideNgxMask(),
  ],
})
export class InputPhoneComponent implements ControlValueAccessor {
  value = '';

  disabled = input<boolean>(false);
  label = input<string>('Телефон');
  withLabel = input<boolean>(true);
  error = input<boolean>(false);
  name = input.required<string>();
  id = input.required<string>();
  placeholder = input<string>('Телефон');
  showMask = input<boolean>(true);

  public onChangeFn!: (value: string) => void;
  public onTouchedFn!: () => void;

  public registerOnChange(fn: VoidFunction): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: VoidFunction): void {
    this.onTouchedFn = fn;
  }

  public writeValue(newValue: string): void {
    this.value = newValue;
  }

  public onChange() {
    this.onChangeFn(this.value);
  }

  validate() {
    //
  }
}

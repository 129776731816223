import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { ROUTE_ANNOUNCEMENT_CREATE, ROUTE_PROFILE } from '@settings/routes';
import { myAnnouncementsMenuList } from './core/myAnnouncementsMenuList';
import { TitleComponent } from '@components/common/title/title.component';
import { TabsComponent } from '@components/common/tabs/tabs.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';

import { MyAnnouncementsService } from './core/myAnnouncements.service';
import { UIService } from '@services/ui.service';
import { ProfileService } from '@services/profile.service';

@Component({
  selector: 'app-my-announcements',
  imports: [
    TitleComponent,
    TabsComponent,
    RouterOutlet,
    RouterLink,
    MobileHeaderComponent,
    LinkBackComponent,
    MatIconModule,
  ],
  templateUrl: './my-announcements.component.html',
  styleUrl: './my-announcements.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAnnouncementsComponent {
  uiService = inject(UIService);
  profileService = inject(ProfileService);
  myAnnouncementsService = inject(MyAnnouncementsService);

  isMobile = this.uiService.isMobile;
  tabList = myAnnouncementsMenuList;

  myAnnouncementsStatusesCount =
    this.myAnnouncementsService.myAnnouncementsStatusesCount;

  routeProfile = `/${ROUTE_PROFILE}`;
  announcementCreateLink = `/${ROUTE_ANNOUNCEMENT_CREATE}`;
}

<div class="base-text">
  {{ announcement().created_at | date: "dd.MM.yyyy HH:mm" }}
</div>

@if (status() === "draft") {
  <div *ngIf="announcement().name" class="title">{{ announcement().name }}</div>
} @else {
  <a
    [routerLink]="[
      '/',
      ROUTE_ANNOUNCEMENT,
      announcement().category.code,
      announcement().slug,
    ]"
    [queryParams]="{ source: source() }"
    *ngIf="announcement().name"
    class="title"
    >{{ announcement().name }}</a
  >
}

<div class="price-block">
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="
      !editMode() && variety() === 'active' && !announcement().is_contract_price
    "
    [class.disabled]="loading()"
    (click)="startEditPrice($event)"
    (keydown)="startEditPrice($event)"
    tabindex="0"
  >
    <rect width="46" height="46" rx="23" fill="#EDF5FF" />
    <path
      d="M25.3617 16.6515L26.8416 15.1715C27.6227 14.3904 28.889 14.3904 29.6701 15.1715L31.0843 16.5857C31.8653 17.3667 31.8653 18.6331 31.0843 19.4141L29.6043 20.8941M25.3617 16.6515L15.746 26.2671C15.4139 26.5992 15.2093 27.0375 15.1679 27.5053L14.9258 30.2458C14.8711 30.8658 15.3899 31.3847 16.01 31.3299L18.7504 31.0878C19.2182 31.0465 19.6565 30.8418 19.9886 30.5098L29.6043 20.8941M25.3617 16.6515L29.6043 20.8941"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="editMode()"
    [class.disabled]="price.invalid || loading() || loadingEditPrice()"
    (click)="submitPrice($event)"
    (keydown)="submitPrice($event)"
    tabindex="1"
  >
    <rect width="46" height="46" rx="23" fill="#629EE4" />
    <path
      d="M31 17.6667L20 28.3334L15 23.4849"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <div class="price">
    @if (announcement().is_contract_price) {
      Договорная
    } @else {
      @if (editMode()) {
        <app-input-label
          id="price"
          name="price"
          placeholder="Цена"
          [formControl]="price"
          [maskTyped]="false"
          inputMask="separator.2"
          inputMode="decimal"
          [error]="price.invalid"
          [disabled]="loadingEditPrice()"
          class="price-input"
          (keydown.enter)="submitPrice($event)"
        />
        руб. / т
      } @else {
        @if (announcement().price) {
          {{ announcement().price | number: "1.0-2" : "ru-Ru" }} руб. / т
        } @else {
          Цена не указана
        }
      }
    }
    <sup *ngIf="!announcement().is_contract_price && announcement().price">{{
      announcement().is_with_vat ? "С НДС" : "Без НДС"
    }}</sup>
  </div>
</div>

<div *ngIf="announcement().weight" class="base-text">
  Объём:
  {{ announcement().weight | number: "1.0-2" : "ru-Ru" }} т
</div>

<div class="base-text">
  {{ announcement().type.value === "sell" ? "Продажа" : "Покупка" }}
</div>

<div *ngIf="announcement().address" class="base-text">
  Адрес:
  {{ announcement().address }}
</div>

import {
  afterNextRender,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  signal,
} from '@angular/core';
import {
  distinctUntilChanged,
  fromEvent,
  map,
  pairwise,
  tap,
  throttleTime,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appDynamicHeader]',
  host: {
    '[style.position]': '"sticky"',
    '[style.display]': '"block"',
    '[style.top]': 'top() + "px"',
    '[style.zIndex]': '4',
    '[style.transition]': '"all .4s ease"',
  },
})
export class DynamicHeaderDirective {
  elementRef = inject(ElementRef);
  destroyRef = inject(DestroyRef);

  top = signal(0);

  constructor() {
    afterNextRender(() => {
      fromEvent(window, 'scroll', { passive: true })
        .pipe(
          map(() => window.scrollY),
          distinctUntilChanged(),
          pairwise(),
          throttleTime(200),
          takeUntilDestroyed(this.destroyRef),
          tap((scrollY) => {
            if (scrollY[0] > scrollY[1]) {
              this.top.set(0);
            } else {
              this.top.set(
                -this.elementRef.nativeElement.getBoundingClientRect().height,
              );
            }
          }),
        )
        .subscribe();
    });
  }
}

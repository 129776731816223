import {
  afterNextRender,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  model,
  output,
  signal,
  ChangeDetectionStrategy,
} from '@angular/core';
import { filter, fromEvent, map } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgClass, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import { MatCheckbox } from '@angular/material/checkbox';

import { ButtonComponent } from '@components/ui/button/button.component';
import { PriorityIconComponent } from '@components/ui/icons/priority-icon.component';
import { CrossIconComponent } from '@components/ui/icons/cross-icon.component';
import { PriorityService } from '@components/pages/profile/profilePages/priority/core/priority.service';
import { TToggleCategoriesTreeEntity } from '@components/pages/profile/profilePages/priority/core/priorityTypes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TAnnouncementType } from '@models/announcementTypes';
import { NotificationService } from '@services/notification.service';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { UIService } from '@services/ui.service';
import { defaultAnnouncementType } from '@utils/defaultAnnouncementType';

@Component({
  selector: 'app-priority-button',
  imports: [
    ButtonComponent,
    PriorityIconComponent,
    CrossIconComponent,
    MatAccordion,
    MatCheckbox,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    NgIf,
    IconButtonComponent,
    NgClass,
  ],
  templateUrl: './priority-button.component.html',
  styleUrl: './priority-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityButtonComponent {
  priorityButton = inject(ElementRef);
  destroyRef = inject(DestroyRef);
  priorityService = inject(PriorityService);
  route = inject(ActivatedRoute);
  uiService = inject(UIService);

  // должно влиять только на стили
  mobileDrawerMode = input<boolean>(false);
  opened = model<boolean>(false);

  updatedPriorities = output();
  openModal = output();
  isMobile = this.uiService.isMobile;

  stickied = signal<boolean>(false);
  hovered = signal<boolean>(false);

  categoryLoading = signal(false);
  announcementType = toSignal<TAnnouncementType>(
    this.route.queryParams.pipe(
      map(
        (params): TAnnouncementType =>
          defaultAnnouncementType(params['type_value']),
      ),
    ),
  );
  notification = inject(NotificationService);

  defaultLoading = this.priorityService.defaultLoading;
  categoryList = this.priorityService.listSelectedCategories;

  showFullButton = computed(
    () => (!this.stickied() || this.hovered()) && !this.isMobile(),
  );

  constructor() {
    afterNextRender(() => {
      fromEvent(window, 'scroll', { passive: true })
        .pipe(
          map(
            () =>
              this.priorityButton?.nativeElement.getBoundingClientRect().top,
          ),
          filter(Boolean),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((buttonTop) => {
          this.stickied.set(buttonTop <= 180);
        });
    });
  }

  changeAnnouncementType = effect(() => {
    const annType = this.announcementType();
    if (annType) {
      this.priorityService.loadListSelectedCategories({
        type: annType,
      });
    }
  });

  clicked() {
    this.opened.update((opened) => !opened);

    if (this.opened()) {
      this.openModal.emit();
    }
  }

  toggleCategoriesTreeEntity(event: Event, ids: TToggleCategoriesTreeEntity) {
    event.stopPropagation();
    event.preventDefault();

    this.priorityService.toggleCategoriesTreeEntity(ids);
  }

  applyFilter() {
    this.categoryLoading.set(true);

    this.priorityService
      .setListSelectedCategories(
        this.announcementType() || defaultAnnouncementType(),
        this.priorityService.createCategoriesRequestData(),
      )
      .subscribe({
        next: () => {
          this.categoryLoading.set(false);
          this.updatedPriorities.emit();
          if (!this.mobileDrawerMode()) {
            this.opened.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.categoryLoading.set(false);

          if (error.formattedErrorMessage)
            this.notification.error(error.formattedErrorMessage);
        },
      });
  }

  resetFilter() {
    this.categoryLoading.set(true);

    this.priorityService
      .setListSelectedCategories(
        this.announcementType() || defaultAnnouncementType(),
        [],
      )
      .subscribe({
        next: () => {
          this.priorityService.loadListSelectedCategories({
            type: this.announcementType() || defaultAnnouncementType(),
          });
          this.categoryLoading.set(false);
          this.updatedPriorities.emit();
          if (!this.mobileDrawerMode()) {
            this.opened.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.categoryLoading.set(false);

          if (error.formattedErrorMessage)
            this.notification.error(error.formattedErrorMessage);
        },
      });
  }
}

import { Component, computed, inject, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { AuthService } from '@services/auth.service';
import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_CONTACTS,
  ROUTE_PRICE_ANALYSIS,
  ROUTE_PROFILE,
} from '@settings/routes';
import { MapLabelsService } from '@components/pages/map/map-labels.service';
import { ProfileService } from '@services/profile.service';
import { FilterIconComponent } from '@components/ui/icons/filter-icon.component';

@Component({
  selector: 'app-mobile-menu',
  imports: [
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    NgClass,
    FilterIconComponent,
  ],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
})
export class MobileMenuComponent {
  profileService = inject(ProfileService);

  constructor(
    private readonly authService: AuthService,
    private readonly map: MapLabelsService,
  ) {}

  isAuth = this.authService.isAuth;
  mapUrl = this.map.mapUrl;

  profileOrAuthLink = computed(() =>
    this.isAuth() ? `/${ROUTE_PROFILE}` : `/${ROUTE_AUTH}`,
  );
  announcementCreateLink = `/${ROUTE_ANNOUNCEMENT_CREATE}`;
  analysisLink = `/${ROUTE_PRICE_ANALYSIS}`;
  contactsLink = `/${ROUTE_CONTACTS}`;

  logOut = output();
  logOutFunction() {
    this.logOut.emit();
  }
}

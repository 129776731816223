import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { promoItemsFirstList, promoItemsSecondList } from './promoItemsList';
import { PromoImageComponent } from './promo-image/promo-image.component';
import { LogoLinkComponent } from '@components/common/logo-link/logo-link.component';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-promo',
  imports: [PromoImageComponent, LogoLinkComponent],
  templateUrl: './promo.component.html',
  styleUrl: './promo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoComponent {
  uiService = inject(UIService);

  itemsFist = promoItemsFirstList;
  itemsSecond = promoItemsSecondList;

  isMobile = this.uiService.isMobile;

  // todo Добавить ссылку
  trialLink = '/';
}

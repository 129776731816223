import { Component, input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-check-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8 5L6.75 11.05L4 8.3"
      [attr.stroke]="stroke()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckIconComponent {
  stroke = input('#050609');
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { SearchWithButtonComponent } from '@components/ui/search/search-with-button/search-with-button.component';
import { SelectLocationComponent } from '@components/ui/select-location/select-location.component';
import { TitleComponent } from '@components/common/title/title.component';
import { ProfileSidebarComponent } from '../../profile-sidebar/profile-sidebar.component';
import { ROUTE_SEARCH } from '@settings/routes';

@Component({
  selector: 'app-mobile-profile',
  imports: [
    MobileHeaderComponent,
    SearchWithButtonComponent,
    SelectLocationComponent,
    ProfileSidebarComponent,
    TitleComponent,
  ],
  templateUrl: './mobile-profile.component.html',
  styleUrl: './mobile-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileProfileComponent {
  router = inject(Router);

  // Поиск по объявлениям
  searchAnnouncements(search: string) {
    this.router.navigate(['/', ROUTE_SEARCH], { queryParams: { search } });
  }
}

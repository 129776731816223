import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';

@Component({
  selector: 'app-button-text',
  imports: [],
  templateUrl: './button-text.component.html',
  styleUrl: './button-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonTextComponent {
  disabled = input<boolean>(false);

  pressed = output();
  pressedFunction() {
    this.pressed.emit();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { BackLinkComponent } from '@components/common/back-link/back-link.component';
import { ROUTE_AUTH } from '@settings/routes';
import { LabelComponent } from '@components/ui/label/label.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { CheckIconComponent } from '@components/ui/icons/check-icon.component';
import { RegistrationService } from '@components/pages/registration/core/registration.service';
import { TTraderKind } from '@components/pages/registration/core/registrationTypes';

const AccountTypes: readonly { type: TTraderKind; name: string }[] = [
  { type: 'farmer', name: 'Фермер' },
  { type: 'trader', name: 'Трейдер' },
  { type: 'recycler', name: 'Зернопереработчик' },
  { type: 'seed_producer', name: 'Производитель семян' },
];

// type TAccountType = keyof typeof AccountTypes;
// type TAccountType = (typeof AccountTypes)[number];

@Component({
  selector: 'app-choose-account-type',
  imports: [
    BackLinkComponent,
    LabelComponent,
    ButtonComponent,
    CheckIconComponent,
  ],
  templateUrl: './choose-account-type.component.html',
  styleUrl: './choose-account-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseAccountTypeComponent {
  registrationService = inject(RegistrationService);

  loading = this.registrationService.loading;

  selectedAccountType = signal<TTraderKind | undefined>(undefined);

  authRoute = `/${ROUTE_AUTH}`;
  accountTypes = AccountTypes;

  selectAccountType(accountType: TTraderKind) {
    this.selectedAccountType.set(accountType);
  }

  protected readonly AccountTypes = AccountTypes;

  typeSelected() {
    const accountType = this.selectedAccountType();
    if (accountType) {
      this.registrationService.setAccountType(accountType).subscribe();
    }
  }
}

import { Component, input, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ROUTE_HOME } from '@settings/routes';

@Component({
  selector: 'app-logo-link',
  imports: [RouterLink],
  templateUrl: './logo-link.component.html',
  styleUrl: './logo-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoLinkComponent {
  homeLink = ROUTE_HOME;

  fillLogo = input<string | null>(null);
  skipLogo = input<boolean>(false);
  height = input<number | undefined>(undefined);
  fillText = input<string | null>(null);
}

@if (error()) {
  <app-info-block
    variety="error"
    [description]="error() || 'Неизвестная ошибка'"
  />
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && favouritesUsers().length < 1"
  emptyDescription="Список избранных пользователей пуст"
>
  <div class="favourites-users">
    @for (user of favouritesUsers(); track user.id) {
      <div class="favourite-user">
        <div class="favourite-user-data">
          <div class="data">
            <div class="avatar">
              @if (user.avatar) {
                <img [src]="user.avatar" alt="Photo" />
              } @else {
                <svg
                  width="84"
                  height="84"
                  viewBox="0 0 84 84"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="42" cy="42" r="42" fill="#F9F9F9" />
                  <path
                    d="M35 52.5V50.1667C35 48.929 35.4917 47.742 36.3668 46.8668C37.242 45.9917 38.429 45.5 39.6667 45.5H44.3333C45.571 45.5 46.758 45.9917 47.6332 46.8668C48.5083 47.742 49 48.929 49 50.1667V52.5M37.3333 36.1667C37.3333 37.4043 37.825 38.5913 38.7002 39.4665C39.5753 40.3417 40.7623 40.8333 42 40.8333C43.2377 40.8333 44.4247 40.3417 45.2998 39.4665C46.175 38.5913 46.6667 37.4043 46.6667 36.1667C46.6667 34.929 46.175 33.742 45.2998 32.8668C44.4247 31.9917 43.2377 31.5 42 31.5C40.7623 31.5 39.5753 31.9917 38.7002 32.8668C37.825 33.742 37.3333 34.929 37.3333 36.1667Z"
                    stroke="#878787"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            </div>

            <div class="description">
              <div class="description-item">
                ID пользователя:
                <a [routerLink]="['/', ROUTE_USER_CONTACTS, user.id]">{{
                  user.id
                }}</a>
              </div>
              <div class="description-item">
                {{
                  isMobile() && user.fio ? (user.fio | userShortName) : user.fio
                }}
              </div>
              <div class="description-item">
                {{ user.announcements.total_count }}
                {{
                  user.announcements.total_count
                    | declense: ["объявление", "объявления", "объявлений"]
                }}
              </div>
            </div>

            <a
              [routerLink]="['/', ROUTE_USER_CONTACTS, user.id]"
              class="adaptive-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M7.875 15.75L13.125 10.5L7.875 5.25"
                  stroke="#878787"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>

          <app-button
            variety="grey"
            width="stretch"
            [disabled]="deletingUserLoading()"
            (pressed)="deletingUserFromFavorites(user.id)"
            class="deleting-user-button"
            >Отписаться</app-button
          >
        </div>

        <div class="favourite-user-announcements">
          @for (
            announcement of user.announcements.last;
            let index = $index;
            track announcement.slug
          ) {
            <app-announcement-card
              source="favourites-users"
              [announcement]="announcement"
              [isMyAnnouncement]="announcement.is_my"
              [isFavouritesLoading]="isFavouritesLoading()"
              (addingToFavourite)="
                changeUserAnnouncementIsFavourite({
                  data: $event,
                  userId: user.id,
                })
              "
            />
          }
        </div>

        <a
          [routerLink]="['/', ROUTE_USER_CONTACTS, user.id]"
          class="favourite-link"
          >Посмотреть все →</a
        >
      </div>
    }
  </div>

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>

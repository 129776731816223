import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';

import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { RegistrationService } from '../../core/registration.service';
import { ButtonComponent } from '@components/ui/button/button.component';
import {
  EMAIL_PATTERN,
  INN_PATTERN,
  ONLY_LETTERS_PATTERN,
  USER_NAME_PATTERN,
} from '@settings/validationSettings';
import { ErrorComponent } from '@components/common/error/error.component';
import { TTraderKind } from '@components/pages/registration/core/registrationTypes';

@Component({
  selector: 'app-set-profile',
  imports: [
    FormsModule,
    InputLabelComponent,
    ButtonComponent,
    MatRadioModule,
    ErrorComponent,
    NgIf,
  ],
  templateUrl: './set-profile.component.html',
  styleUrl: './set-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetProfileComponent {
  accountType = input.required<TTraderKind | null>();

  error = this.registrationService.error;
  loading = this.registrationService.loading;

  emailPattern = EMAIL_PATTERN;
  userNamePattern = USER_NAME_PATTERN;
  onlyLettersPattern = ONLY_LETTERS_PATTERN;
  innPattern = INN_PATTERN;

  constructor(private registrationService: RegistrationService) {}

  setProfile(setProfileForm: NgForm) {
    this.registrationService.setProfileData(setProfileForm.value);
  }
}

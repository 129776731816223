import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import { TAddresses, TGeocoding } from '@models/mapTypes';
import { NotificationService } from './notification.service';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MapService {
  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #error = signal<HttpErrorResponse | null>(null);
  error = computed(this.#error);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  // Получение геоданных по адресу
  // address - полный адрес
  loadGeocoding(address: string): Observable<TGeocoding> {
    return this.http.get<TGeocoding>(
      `${environment.getApiVersionUrl('map/geocoding')}`,
      {
        params: {
          address,
        },
      },
    );
  }

  // Поиск адреса
  // address - часть адреса
  findAddress(address: string): Observable<TAddresses> {
    return this.http.get<TAddresses>(
      `${environment.getApiVersionUrl('map/find-address')}`,
      {
        params: {
          address,
        },
        withCredentials: true,
      },
    );
  }
}

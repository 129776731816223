import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnInit,
  effect,
  inject,
  input,
  output,
  untracked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { TAddresses } from '@models/mapTypes';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { AddressStringPipe } from '@pipes/address-string.pipe';

@Component({
  selector: 'app-input-address',
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgIf,
    MatAutocompleteModule,
    LoaderComponent,
    AddressStringPipe,
  ],
  templateUrl: './input-address.component.html',
  styleUrl: './input-address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAddressComponent implements OnInit {
  placeholder = input<string>('Адрес');
  initialAddress = input<string>('');
  addressList = input<TAddresses>([]);

  error = input<string>('');
  showError = input<boolean>(false);
  loading = input<boolean>(false);
  addressesRequested = input<boolean>(false);

  textAreaMode = input<boolean>(false);

  value = '';
  readonly control = new FormControl<string>(this.value, {
    nonNullable: true,
    validators: [Validators.maxLength(255)],
  });

  address = output<string>();
  blurAddress = output();
  blurFunction() {
    this.blurAddress.emit();
  }

  destroyRef = inject(DestroyRef);

  changeInitialAddress = effect(() => {
    if (this.initialAddress()) {
      this.control.setValue(this.initialAddress());

      untracked(() => {
        this.address.emit(this.initialAddress());
      });
    }
  });

  clear() {
    this.control.setValue('');
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(debounceTime(1000), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.address.emit(value);
      });
  }
}

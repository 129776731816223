<form
  name="formChangeProfile"
  #formChangeProfile="ngForm"
  (ngSubmit)="handleSubmit()"
  class="profile-form"
>
  <app-info-block
    *ngIf="!!apiError()"
    [description]="apiError() || 'Неизвестная ошибка'"
    variety="error"
  />

  <hr class="top" />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Фамилия
        <sup class="required">*</sup>
      </div>
      <div *ngIf="!!last_name.invalid && !!last_name.touched" class="error">
        Обязательное поле (от 1 до 255 символов русского алфавита)
      </div>
    </div>
    <app-input-label
      id="last_name"
      name="last_name"
      #last_name="ngModel"
      [(ngModel)]="profileChangeForm.last_name"
      placeholder="Фамилия"
      [disabled]="loading()"
      [required]="true"
      [pattern]="userNamePattern"
      [maxlength]="255"
      [error]="!!last_name.invalid && !!last_name.touched"
      class="item-input"
    />
  </div>

  <hr />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Имя
        <sup class="required">*</sup>
      </div>
      <div *ngIf="!!first_name.invalid && !!first_name.touched" class="error">
        Обязательное поле (от 1 до 255 символов русского алфавита)
      </div>
    </div>
    <app-input-label
      id="first_name"
      name="first_name"
      #first_name="ngModel"
      [(ngModel)]="profileChangeForm.first_name"
      placeholder="Имя"
      [disabled]="loading()"
      [required]="true"
      [pattern]="userNamePattern"
      [maxlength]="255"
      [error]="!!first_name.invalid && !!first_name.touched"
      class="item-input"
    />
  </div>

  <hr />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">Отчество</div>
      <div *ngIf="!!middle_name.invalid && !!middle_name.touched" class="error">
        До 255 символов русского алфавита
      </div>
    </div>
    <app-input-label
      id="middle_name"
      name="middle_name"
      #middle_name="ngModel"
      [(ngModel)]="profileChangeForm.middle_name"
      placeholder="Отчество"
      [disabled]="loading()"
      [pattern]="onlyLettersPattern"
      [maxlength]="255"
      [error]="!!middle_name.invalid && !!middle_name.touched"
      class="item-input"
    />
  </div>

  <hr *ngIf="profileData()?.type !== 'recycler'" />
  <div class="profile-form-item" *ngIf="profileData()?.type !== 'recycler'">
    <div class="description">
      <div class="label">ИНН</div>
      <div *ngIf="!!inn.invalid && !!inn.touched" class="error">
        10 или 12 цифр
      </div>
    </div>
    <app-input-label
      id="inn"
      name="inn"
      #inn="ngModel"
      [(ngModel)]="profileChangeForm.inn"
      placeholder=""
      [disabled]="loading()"
      inputMask="0000000000||000000000000"
      inputMode="numeric"
      [pattern]="innPattern"
      [error]="!!inn.invalid && !!inn.touched"
      class="item-input"
    />
  </div>

  <hr *ngIf="profileData()?.type !== 'recycler'" />
  <div class="profile-form-item" *ngIf="profileData()?.type !== 'recycler'">
    <div class="description">
      <div class="label">Адрес</div>
      <div class="description-text">
        Адрес будет автоматически заполняться при создании объявления
      </div>
      <div *ngIf="showErrorAddress() && errorAddress()" class="error">
        {{ errorAddress() }}
      </div>
    </div>
    <app-input-address
      id="address_data"
      [textAreaMode]="true"
      [loading]="loadingAddressList()"
      [addressesRequested]="addressesRequested()"
      [initialAddress]="initialAddress()"
      [addressList]="addressList()"
      (blurAddress)="handleBlurAddress()"
      (address)="onSearchAddress($event)"
      [error]="errorAddress()"
      [showError]="showErrorAddress()"
      class="item-input"
    />
  </div>

  <hr />
  <app-button
    variety="secondary"
    type="submit"
    [disabled]="!!formChangeProfile.invalid || !!errorAddress()"
    >Сохранить</app-button
  >
</form>

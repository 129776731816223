export type TPromoItemsList = {
  id: number;
  name: string;
}[];

export const promoItemsSecondList: TPromoItemsList = [
  { id: 1, name: 'фермеров' },
  { id: 2, name: 'трейдеров' },
  { id: 3, name: 'переработчиков' },
  { id: 4, name: 'производителей семян' },
];

export const promoItemsFirstList: TPromoItemsList = [
  { id: 4, name: 'Прямые контакты поставщиков и покупателей' },
  { id: 5, name: 'Быстрый поиск нужного сырья посредством фильтров' },
  { id: 6, name: 'Регистрация на платформе только проверенных контрагентов' },
  { id: 7, name: 'Доступный и объективный раздел "Аналитика цен"' },
];

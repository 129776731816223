<div class="set-organization-component">
  <div class="title">Введите данные организации</div>

  @if (!!error()) {
    <app-error
      [errorText]="error()?.formattedErrorMessage || 'Неизвестная ошибка'"
    />
  }

  <form
    #setOrganizationForm="ngForm"
    (ngSubmit)="setOrganization(setOrganizationForm)"
    class="set-organization-form"
  >
    <div>
      <app-input-label
        id="name"
        name="name"
        #name="ngModel"
        ngModel
        label="Наименование организации"
        placeholder="Наименование организации"
        [disabled]="loading()"
        [required]="true"
        [maxlength]="255"
        [error]="!!name.invalid && !!name.touched"
      />
      <div *ngIf="!!name.invalid && !!name.touched" class="error">
        Обязательное поле (от 1 до 255 символов)
      </div>
    </div>

    <div>
      <app-input-label
        id="inn"
        name="inn"
        #inn="ngModel"
        ngModel
        label="ИНН"
        placeholder=""
        [disabled]="loading()"
        inputMask="0000000000||000000000000"
        inputMode="numeric"
        [pattern]="innPattern"
        [error]="!!inn.invalid && !!inn.touched"
      />
      <div *ngIf="!!inn.invalid && !!inn.touched" class="error">
        10 или 12 цифр
      </div>
    </div>

    <div>
      <app-input-address
        id="address_data"
        [textAreaMode]="true"
        [loading]="loadingAddressList()"
        [addressesRequested]="addressesRequested()"
        [initialAddress]="initialAddress()"
        [addressList]="addressList()"
        (blurAddress)="handleBlurAddress()"
        (address)="onSearchAddress($event)"
        [error]="errorAddress()"
        [showError]="showErrorAddress()"
        class="item-input"
      />
    </div>

    <app-button
      variety="secondary"
      width="stretch"
      type="submit"
      class="submit-button without-top-margin mb-24"
      [disabled]="loading() || !!setOrganizationForm.invalid"
      >Далее
    </app-button>
  </form>
</div>

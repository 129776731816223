import {
  Component,
  afterNextRender,
  ChangeDetectionStrategy,
} from '@angular/core';

import { ExternalLayoutComponent } from '@components/layouts/external-layout/external-layout.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { ROUTE_AUTH } from '@settings/routes';
import { SetPhoneComponent } from './steps/set-phone/set-phone.component';
import { ConfirmPhoneComponent } from './steps/confirm-phone/confirm-phone.component';
import { SetPasswordComponent } from './steps/set-password/set-password.component';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { ConfirmEmailComponent } from './steps/confirm-email/confirm-email.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';

import { RegistrationService } from './core/registration.service';
import { StaticPagesService } from '../static-pages/core/static-pages.service';
import { ChooseAccountTypeComponent } from '@components/pages/registration/steps/choose-account-type/choose-account-type.component';
import { SetProfileComponent } from '@components/pages/registration/steps/set-profile/set-profile.component';
import { SetOrganizationComponent } from '@components/pages/registration/steps/set-organization/set-organization.component';

@Component({
  selector: 'app-registration',
  imports: [
    ExternalLayoutComponent,
    LinkComponent,
    InfoBlockComponent,
    SetPhoneComponent,
    ConfirmPhoneComponent,
    SetProfileComponent,
    SetPasswordComponent,
    ConfirmEmailComponent,
    LoaderComponent,
    ChooseAccountTypeComponent,
    SetOrganizationComponent,
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent {
  currentStep = this.registrationService.currentStep;
  currentAccountType = this.registrationService.currentAccountType;
  loadingStep = this.registrationService.loadingStep;
  errorCurrentStep = this.registrationService.errorCurrentStep;

  authRoute = `/${ROUTE_AUTH}`;

  constructor(
    private registrationService: RegistrationService,
    private pagesService: StaticPagesService,
  ) {
    afterNextRender(() => {
      this.pagesService.loadRegistrationPages();
      this.registrationService.loadCurrentStep();
    });
  }
}

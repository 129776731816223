<app-back-link [link]="authRoute" />
<div class="title mb-16">Регистрация</div>
<app-label label="Выберите тип вашего аккаунта" />

<div class="account-types">
  @for (accountType of accountTypes; track accountType.type) {
    <app-button
      class="account"
      [class.selected]="selectedAccountType() === accountType.type"
      width="stretch"
      [height]="77"
      (pressed)="selectAccountType(accountType.type)"
      ><div class="account-title">
        {{ accountType.name }}
        @if (selectedAccountType() === accountType.type) {
          <app-check-icon class="checkbox-icon" stroke="white" />
        }</div
    ></app-button>
  }
</div>

<app-button
  variety="secondary"
  width="stretch"
  (pressed)="typeSelected()"
  class="submit-button mb-24"
  [disabled]="loading() || !selectedAccountType()"
  >Далее</app-button
>

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { TMainPageCategory } from '@models/commonTypes';
import { ROUTE_CATEGORY } from '@settings/routes';
import { TAnnouncementType } from '@models/announcementTypes';

@Component({
  selector: 'app-category-link',
  imports: [NgClass, RouterLink],
  templateUrl: './category-link.component.html',
  styleUrl: './category-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryLinkComponent {
  category = input.required<TMainPageCategory>();
  allottedCategory = input<boolean>(false);
  width = input<string>('64px');
  height = input<string>('64px');

  route = inject(ActivatedRoute);

  categoryLink = computed(() => {
    const baseUrl = [ROUTE_CATEGORY, this.category().category_code];
    const cerealSlug = this.category().cereal_slug;
    return cerealSlug ? [...baseUrl, cerealSlug] : baseUrl;
  });

  queryParams = toSignal(
    this.route.queryParams.pipe(
      map((params) => {
        const typeSell: TAnnouncementType =
          params['type_value'] === 'buy' ? 'buy' : 'sell';
        return { type_value: typeSell };
      }),
    ),
  );
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-up-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width()"
    [attr.height]="height()"
    viewBox="0 0 24 24"
    [attr.fill]="fill()"
  >
    <path
      d="M12 19L12 5"
      [attr.stroke]="stroke()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 12L12 5L19 12"
      [attr.stroke]="stroke()"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpIconComponent {
  stroke = input<string>('#050609');
  fill = input<string>('none');
  width = input<string>('26px');
  height = input<string>('26px');
}

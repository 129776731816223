import {
  Component,
  OnInit,
  afterNextRender,
  ChangeDetectionStrategy,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { ReferencesService } from '@services/references.service';
import { ContactsService } from '@components/pages/contacts/core/contacts.service';
import { PriorityService } from '@components/pages/profile/profilePages/priority/core/priority.service';
import { StaticPagesService } from '@components/pages/static-pages/core/static-pages.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private referencesService: ReferencesService,
    private priorityService: PriorityService,
    private pagesService: StaticPagesService,
    private contactsService: ContactsService,
    private authService: AuthService,
  ) {
    afterNextRender(() => {
      if (this.authService.isKnownUser() && !this.authService.isAuth()) {
        this.priorityService.loadListSelectedRegions();
      }
    });
  }

  ngOnInit(): void {
    this.contactsService.loadFooterContacts();
    this.referencesService.loadAllReferences();
    this.pagesService.loadPages();

    // Проверка авторизованности пользователя
    this.authService.checkAuth();
  }
}

import {
  Component,
  DestroyRef,
  afterNextRender,
  inject,
  signal,
  ChangeDetectionStrategy,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewportScroller } from '@angular/common';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  throttleTime,
} from 'rxjs';

import { DataWrapperComponent } from '@components/common/data-wrapper/data-wrapper.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { AnnouncementCardComponent } from '@components/pages/home/announcement-card/announcement-card.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { TChangeUserAnnouncementFavourite } from '@components/pages/home/core/announcementsListTypes';
import { ROUTE_USER_CONTACTS } from '@settings/routes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { UserShortNamePipe } from '@pipes/user-short-name.pipe';
import { DeclensePipe } from '@pipes/declense.pipe';

import { FavouritesService } from '../../core/favourites.service';
import { NotificationService } from '@services/notification.service';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-favourites-users',
  imports: [
    DataWrapperComponent,
    LoaderGlobalComponent,
    InfoBlockComponent,
    AnnouncementCardComponent,
    ButtonComponent,
    DeclensePipe,
    RouterLink,
    UserShortNamePipe,
  ],
  templateUrl: './favourites-users.component.html',
  styleUrl: './favourites-users.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritesUsersComponent {
  favouritesUsers = this.favouritesService.favouritesUsersList;
  isFavouritesLoading = signal(false);
  deletingUserLoading = signal(false);
  isLoading = this.favouritesService.usersLoader;
  isInfiniteLoading = this.favouritesService.usersInfiniteLoader;
  error = this.favouritesService.usersError;

  isMobile = this.uiService.isMobile;

  destroyRef = inject(DestroyRef);

  protected readonly ROUTE_USER_CONTACTS = ROUTE_USER_CONTACTS;

  constructor(
    private favouritesService: FavouritesService,
    private notificationService: NotificationService,
    private uiService: UIService,
    private scroll: ViewportScroller,
  ) {
    afterNextRender(() => {
      this.scroll.scrollToPosition([0, 0]);

      this.favouritesService.resetFavouritesData();
      this.favouritesService.loadFavouritesUsers({});
      this.favouritesService.loadFavouritesCounts();

      // Высота footer или мобильного footer + половина высоты карточки объявления
      const footerHeight =
        document.getElementById('footer')?.clientHeight || 70;

      const pageByScroll$ = fromEvent(window, 'scroll', { passive: true }).pipe(
        map(() => window.scrollY),
        filter(
          (scrollY) =>
            scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footerHeight -
              210,
        ),
        throttleTime(200),
        distinctUntilChanged(),
      );

      pageByScroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        if (!this.isLoading() && !this.isInfiniteLoading())
          this.favouritesService.loadFavouritesUsers({
            isInfinite: true,
          });
      });
    });
  }

  deletingUserFromFavorites(id: number) {
    this.deletingUserLoading.set(true);

    this.favouritesService.deletingUserFromFavorites(id).subscribe({
      next: () => {
        this.favouritesService.resetFavouritesData();
        this.favouritesService.loadFavouritesUsers({});
        this.favouritesService.loadFavouritesCounts();

        this.deletingUserLoading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.deletingUserLoading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }

  changeUserAnnouncementIsFavourite({
    data: { slug, is_favorite },
    userId,
  }: TChangeUserAnnouncementFavourite) {
    this.isFavouritesLoading.set(true);

    const changeFunction = !is_favorite
      ? this.favouritesService.addingAnnouncementToFavorites(slug)
      : this.favouritesService.deletingAnnouncementFromFavorites(slug);

    changeFunction.subscribe({
      next: () => {
        this.favouritesService.changeUserAnnouncementIsFavorite(userId, slug);
        this.isFavouritesLoading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.isFavouritesLoading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }
}

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@environments/environment';

export const authHeaderInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);

  if (request.headers.get('skipBearerAuthorization')) {
    return next(request);
  } else {
    if (
      !authService.getTokens().accessToken &&
      !authService.getTokens().refreshToken
    ) {
      if (!environment.production) {
        console.warn(
          'This request run on server without tokens: ',
          request.url,
        );
      }
      if (isPlatformBrowser(platformId)) {
        if (!environment.production) {
          console.warn('Clear tokens and reset user data for: ', request.url);
        }
        authService.resetUserData(true, true);
      }
    }
  }

  // чтобы исключить Bearer: null
  if (authService.getTokens().accessToken) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getTokens().accessToken}`,
      },
    });
  }

  return next(request);
};

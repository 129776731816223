import { DatePipe, DecimalPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { TAnnouncement } from '@models/announcementTypes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_ANNOUNCEMENT } from '@settings/routes';
import { TAnnouncementListData } from '@components/pages/home/core/announcementsListTypes';
import { TBreadcrumbsSource } from '@components/common/breadcrumbs/breadcrumb';

import { AnnouncementService } from '@services/announcement.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-my-announcements-description',
  imports: [
    DatePipe,
    DecimalPipe,
    InputLabelComponent,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
  ],
  templateUrl: './my-announcements-description.component.html',
  styleUrl: './my-announcements-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAnnouncementsDescriptionComponent {
  notificationService = inject(NotificationService);
  announcementService = inject(AnnouncementService);
  loading = this.announcementService.loading;
  loadingEditPrice = signal(false);

  source = input<TBreadcrumbsSource>();

  announcement = input.required<TAnnouncement | TAnnouncementListData>();
  variety = input.required<'active' | 'disabled' | 'draft'>();
  status = input.required<string>();
  editMode = signal<boolean>(false);

  price = new FormControl<number | null>(null, {
    validators: [Validators.min(1), Validators.max(999999999)],
  });

  protected readonly ROUTE_ANNOUNCEMENT = ROUTE_ANNOUNCEMENT;

  startEditPrice(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.price.setValue(this.announcement().price);
    this.editMode.set(true);
    this.price.addValidators(Validators.required);
  }

  submitPrice(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    if (this.price.valid && this.price.value !== null) {
      this.loadingEditPrice.set(true);

      this.announcementService
        .updatePrice(this.announcement().slug, this.price.value)
        .subscribe({
          next: (result) => {
            this.announcement().price = result.price;
            this.price.removeValidators(Validators.required);
            this.loadingEditPrice.set(false);
            this.editMode.set(false);
          },

          error: (error: TFormattedApiError) => {
            this.loadingEditPrice.set(false);

            if (error.formattedErrorMessage)
              this.notificationService.error(error.formattedErrorMessage);
          },
        });
    } else {
      console.error('Ошибка валидации цены');
    }
  }
}

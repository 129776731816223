import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { NgClass } from '@angular/common';

import { UpIconComponent } from '../icons/up-icon.component';
import { PriorityIconComponent } from '@components/ui/icons/priority-icon.component';

export type TIconButtonKind = 'favorite' | 'up' | 'priority';

@Component({
  selector: 'app-icon-button',
  imports: [NgClass, UpIconComponent, PriorityIconComponent],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  disabled = input<boolean>(false);
  variety = input<'white' | 'light' | 'dark' | 'favorite'>('light');
  type = input<'button' | 'submit'>('button');
  kind = input.required<TIconButtonKind>();
  smallSize = input<boolean>(false);

  pressed = output();
  pressedFunction() {
    this.pressed.emit();
  }
}
